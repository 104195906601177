export const OrderCancellationReasons = {
  BY_DRIVER: 1,
  BY_CLIENT: 2,
};

export const DisableAutoAcceptMinutes = {
  DISABLE_AUTOACCEPT_15: 0.2,
  DISABLE_AUTOACCEPT_30: 30,
  DISABLE_AUTOACCEPT_45: 45,
};

