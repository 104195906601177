import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import styles from "./styles.module.scss";
import { ReactComponent as AutoAcceptDisabledIcon } from "src/images/auto-accept-disabled.svg";
import { ReactComponent as AutoAcceptEnabledIcon } from "src/images/auto-accept-enabled.svg";
import useClickOutside from "src/hooks/useClickOutside";
import AutoAcceptDisabledPopup from "../AutoAcceptPopup";
import { t } from "i18next";
import { formatMillisecondsAsTime } from "src/pages/Order/helpers/formatMillisecondsAsTime";

const getRemainingTime = () => {
  const storedTime = localStorage.getItem("autoAcceptDisableUntil");
  const targetTime = storedTime ? moment.utc(storedTime) : null;
  const currentTime = moment.utc();
  return targetTime ? Math.max(targetTime.diff(currentTime, "seconds"), 0) : 0;
};

const AutoAcceptDisabledButton = ({ autoAcceptDisabled, toggleAutoAccept }) => {
  console.log("autoAcceptDisabled",autoAcceptDisabled);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [remainingTime, setRemainingTime] = useState(getRemainingTime());
  const [isTimerEnabled, setIsTimerEnabled] = useState(remainingTime < 1);
  const popupRef = useClickOutside(() => setIsPopupVisible(false));

  // Обновление оставшегося времени
  useEffect(() => {
    const updateRemainingTime = () => {
      const remaining = getRemainingTime();
      setRemainingTime(remaining);
      setIsTimerEnabled(remaining > 0);
    };

    updateRemainingTime();
    const timer = setInterval(updateRemainingTime, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!isTimerEnabled && remainingTime < 1) {
      toggleAutoAcceptRequest(false);
    }
  }, [isTimerEnabled, remainingTime, toggleAutoAcceptRequest]);

  const toggleAutoAcceptRequest = async (state) => {
    try {
      await toggleAutoAccept(state);
      setIsPopupVisible(false);
    } catch (error) {
      console.error("Ошибка при переключении авто-принятия:", error);
    }
  };

  const handleConfirm = async (minutes) => {
    const currentTime = moment.utc();
    let targetTime;

    // Вычисление целевого времени
    if (minutes === 1 || minutes === 2) {
      toggleAutoAcceptRequest(minutes === 1);
      localStorage.removeItem("autoAcceptDisableUntil");
      targetTime = null;
    } else {
      targetTime = moment.utc(localStorage.getItem("autoAcceptDisableUntil") || currentTime).add(minutes, "minutes").toISOString();
      localStorage.setItem("autoAcceptDisableUntil", targetTime);
      toggleAutoAcceptRequest(true);
    }

    setRemainingTime(targetTime ? moment.utc(targetTime).diff(moment.utc(), "seconds") : 0);
  };

  const formattedTime = useMemo(() => {
    if (remainingTime <= 0) return null;

    return t("enable_in_auto_accept", {
      time: formatMillisecondsAsTime(remainingTime * 1000),
    });
  }, [remainingTime]);

  const handleCancel = () => {
    setIsPopupVisible(false);
  };

  return (
    <>
      <button onClick={() => setIsPopupVisible(true)} className={styles.button}>
        {autoAcceptDisabled ? <AutoAcceptDisabledIcon /> : <AutoAcceptEnabledIcon />}
      </button>
      <AutoAcceptDisabledPopup
        formattedTime={formattedTime}
        showPopup={isPopupVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        autoAcceptDisabled={autoAcceptDisabled}
        ref={popupRef}
      />
    </>
  );
};

export default AutoAcceptDisabledButton;
