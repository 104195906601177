
import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";

import { LANGUAGE_NAME } from "src/shared/const/localstorage";

import { axiosInstance, axiosInstanceWithToken } from "../../axios.config";
import { api, apiRpc, apinew } from "../../config";
import { getWithdrawT } from "../GTransactions/gtransactions.actions";

export const REG_INFO = "REG_INFO";
export const REG_STATUS = "REG_STATUS";
export const PROF_INFO = "PROF_INFO";
export const TRY_REG_INFO = "TRY_REG_INFO";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const PHONE_IN_USE = "PHONE_IN_USE";
export const LOGIN_IN_USE = "LOGIN_IN_USE";
export const CLEAR_PHONE_IN_USE = "CLEAR_PHONE_IN_USE";
export const CLEAR_LOGIN_IN_USE = "CLEAR_LOGIN_IN_USE";
export const SET_FIRST_REGISTRATION = "SET_FIRST_REGISTRATION";
export const CLEAR_RPC_ERROR = "CLEAR_RPC_ERROR";

// RPC - auth/reg
export const GET_DRIVER_COORDS_ERROR = "GET_DRIVER_COORDS_ERROR";
export const POST_RPC_INFO_LOADING = "POST_RPC_INFO_LOADING";
export const POST_PRC_INFO_SUCCESS = "POST_PRC_INFO_SUCCESS";
export const POST_PRC_INFO_ERROR = "POST_PRC_INFO_ERROR";

//PROFILE info
export const SET_PROFILE_LANGUAGE = "SET_PROFILE_LANGUAGE";
export const TOGGLE_AUTO_ACCEPT = "TOGGLE_AUTO_ACCEPT";

export const toggleAutoAccept = createAsyncThunk(
  `${TOGGLE_AUTO_ACCEPT}`,
  async (newStatus, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance({
        method: "put",
        url: `${apinew}/profile/auto-accept-disabled`,
        headers: { Authorization: `Bearer ${accessToken}` },
        data: { value: newStatus },
      });

      if (response.status === 200 || response.status === 204) {
        return newStatus; 
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendCoordsToRpc = () => {
  const driverCoords = JSON.parse(localStorage.getItem("coords"));

  return async (dispatch) => {
    dispatch({
      type: POST_RPC_INFO_LOADING,
    });

    if (!driverCoords || !driverCoords.latitude || !driverCoords.longitude) {
      dispatch({
        type: GET_DRIVER_COORDS_ERROR,
      });
      return;
    }

    try {
      const currentLanguage = localStorage.getItem(LANGUAGE_NAME)?.toLowerCase();

      const coordsData = `${driverCoords.latitude} ${driverCoords.longitude}`;

      const response = await axiosInstance.post(apiRpc + "/get-points-country-data", {
        point: coordsData,
        is_authentication: true,
      });

      if (!response.data.success) {
        const errorMessage = response.data.error[currentLanguage];
        dispatch({
          type: POST_PRC_INFO_ERROR,
          payload: errorMessage,
        });
        return;
      }

      dispatch({
        type: POST_PRC_INFO_SUCCESS,
        payload: response.data.country,
      });

      localStorage.setItem("country_code", response.data.country.country_code);
    } catch (error) {
      dispatch({
        type: POST_PRC_INFO_ERROR,
        payload: t("there_was_unexpected_error_try_again"),
      });
    }
  };
};

export const setFirstRegistration = (isFirstRegistration) => {
  return (dispatch) => {
    dispatch({
      type: SET_FIRST_REGISTRATION,
      payload: isFirstRegistration,
    });
  };
};

export const registration = createAsyncThunk(
  "auth/registration",
  async (driverInfo, { dispatch, rejectWithValue }) => {
    try {
      dispatch({
        type: TRY_REG_INFO,
        payload: driverInfo,
      });
      const response = await axiosInstance.post(`${api}/driver/sign-up`, driverInfo);

      if (response?.status === 200 || response?.status === 204) {
        dispatch({
          type: REG_STATUS,
          payload: true,
        });
      } else {
        dispatch({
          type: REG_STATUS,
          payload: false,
        });
      }

      return response;
    } catch (error) {
      if (error.response?.data.Data === "phone_in_use" && error.response?.data.Message?.includes("Phone")) {
        dispatch({
          type: PHONE_IN_USE,
        });
        return;
      }
      if (error.response?.data.Data === "phone_in_use" && error.response?.data.Message?.includes("Login")) {
        dispatch({
          type: LOGIN_IN_USE,
        });
        return;
      }

      return rejectWithValue(error);
    }
  },
);

export const UPD_PROFILE_STATUS = "UPD_PROFILE_STATUS";
export const updDriverInfo = createAsyncThunk(
  "driver/updDriverInfo",
  async ({ driverInfo, showUpdateStatus = true }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstanceWithToken({
        method: "put",
        url: `${apinew}/profile`,
        data: {
          phone: driverInfo.phone,
          email: driverInfo.email,
          firstName: driverInfo.firstName,
          lastName: driverInfo.lastName,
          middleName: driverInfo.middleName,
          photo: driverInfo.photo,
          dateOfBirth: driverInfo.dateOfBirth,
          gender: driverInfo.gender,
          driverLicense: driverInfo.driverLicense,
          defaultCardId: driverInfo.defaultCardId,
          balance: driverInfo.balance,
          commission: driverInfo.commission,
          cityId: driverInfo.cityId,
          orderSearchDistance: driverInfo.orderSearchDistance,
        },
      });

      const showUpdateProfileStatus = response?.status === 200 || response?.status === 204;

      if (showUpdateProfileStatus && showUpdateStatus) {
        dispatch({
          type: UPD_PROFILE_STATUS,
          payload: true,
        });
      }

      if (driverInfo.photo) {
        dispatch({
          type: UPD_PHOTO_STATUS,
          payload: true,
        });
      }

      if (driverInfo.driverLicense) {
        dispatch({
          type: UPD_LICENSE_STATUS,
          payload: true,
        });
      }

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateProfileLanguage = (language) => {
  const accessToken = localStorage.getItem("accessToken");
  const driverId = localStorage.getItem("driverId");
  const appType = localStorage.getItem("appType");

  return async (dispatch) => {
    try {
      await axiosInstance({
        method: "put",
        url: apinew + "/profile/updatelanguage",
        headers: { Authorization: `Bearer ${accessToken}` },
        data: {
          language,
          id: driverId,
        },
      }).then(() => {
        dispatch({
          type: SET_PROFILE_LANGUAGE,
          payload: language,
        });
      });
    } catch (error) {
      console.log("error: updateProfileLanguage didnt update language", error);
    }
  };
};

export const UPD_REGISTRATION_STEP = "UPD_REGISTRATION_STEP";
export const updRegistrationStep = createAsyncThunk(
  "driver/updRegistrationStep",
  async (registrationStep, { rejectWithValue }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      console.log(registrationStep);
      await axiosInstance({
        method: "put",
        url: apinew + "/profile/registrationstep",
        headers: { Authorization: `Bearer ${accessToken}` },
        data: {
          registrationStep: registrationStep,
        },
      });

      localStorage.setItem("registrationStep", registrationStep);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const clearUseLogin = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_LOGIN_IN_USE,
    });
  };
};

export const UPD_PHOTO_STATUS = "UPD_PHOTO_STATUS";
export const UPD_LICENSE_STATUS = "UPD_LICENSE_STATUS";
export const PROF_LOADING = "PROF_LOADING";

export const getDriverInfo = createAsyncThunk(
  "driver/getDriverInfo",
  async (isFinancialPage = false, { dispatch, getState, rejectWithValue }) => {
    dispatch({
      type: PROF_LOADING,
    });

    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await axiosInstance.get(`${apinew}/profile`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      const data = {
        ...response.data.data,
      }

      localStorage.setItem("registrationStep", data.registrationStep);
      localStorage.setItem("userPhone", data.phone);

      if (isFinancialPage) {
        dispatch({
          type: PROF_LOADING,
        });

        const payloadData = {
          ...response?.data.data,
        };
        await dispatch(getWithdrawT());
        const gTransactionsList = getState().gtransactions.gTransactionsList;
        const unapprovedTotalAmount = gTransactionsList.reduce(
          (total, item) => (item.isApproved === null ? total + parseFloat(item.amount.toFixed(1)) : total),
          0,
        );

        if (unapprovedTotalAmount !== 0) {
          payloadData.balance = payloadData.balance - unapprovedTotalAmount;
          payloadData.hasWithdrawRequests = true;
        }

        dispatch({
          type: PROF_INFO,
          payload: payloadData,
        });
      } else {
        if (response?.data.data.photo !== null) {
          dispatch({
            type: UPD_PHOTO_STATUS,
            payload: true,
          });
        }

        if (response?.data.data.driverLicense !== null) {
          dispatch({
            type: UPD_LICENSE_STATUS,
            payload: true,
          });
        }

        dispatch({
          type: PROF_INFO,
          payload: data,
        });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getRegInfo = createAsyncThunk("driver/getRegInfo", async (_, { dispatch, rejectWithValue }) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axiosInstance.get(api + "/driver/me", {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    localStorage.setItem("driverId", response?.data.id);
    dispatch({
      type: REG_INFO,
      payload: response?.data,
    });
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const resetUpdProfileStatus = () => {
  return (dispatch) => {
    dispatch({
      type: UPD_PROFILE_STATUS,
      payload: false,
    });
  };
};

export const clearError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ERROR,
    });
  };
};

export const clearRpcError = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_RPC_ERROR,
    });
  };
};

export const rememberInfo = (info) => {
  return (dispatch) => {
    dispatch({
      type: TRY_REG_INFO,
      payload: info,
    });
  };
};

export const CLEAR_REG = "CLEAR_REG";
export const clearReg = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_REG,
    });
  };
};

export const clearUsePhone = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PHONE_IN_USE,
    });
  };
};
