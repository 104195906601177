import React, { forwardRef } from "react";
import { ReactComponent as WarningIcon } from "src/images/warning-red.svg";
import { BlackButton } from "src/shared/ui/Buttons/BlackButton";
import { BorderedButton } from "src/shared/ui/Buttons/BorderedButton";

import styles from "./styles.module.scss";
import ModalNew from "src/pages/Modals/ModalNew";
import { t } from "i18next";
import { DisableAutoAcceptMinutes } from "src/shared/const/orders";

const AutoAcceptPopup = (
  { formattedTime, onConfirm, onCancel, showPopup, autoAcceptDisabled },
  ref
) => {
  const disableMinutesOptions = [
    DisableAutoAcceptMinutes.DISABLE_AUTOACCEPT_15,
    DisableAutoAcceptMinutes.DISABLE_AUTOACCEPT_30,
    DisableAutoAcceptMinutes.DISABLE_AUTOACCEPT_45
  ];

  const popupInner = (
    <div className={styles.autoAcceptPopup} ref={ref}>
      <WarningIcon className={styles.warningImg} />
      <h2>{t("auto_accept_settings")}</h2>
      <p className={styles.autoAcceptDescription}>
        {t("auto_accept_message")}
      </p>

      {autoAcceptDisabled && formattedTime && (
        <h2> {formattedTime}</h2>
      )}

      <BlackButton onClick={() => onConfirm(autoAcceptDisabled ? 2 : 1)}>
        {autoAcceptDisabled ? t("enable_auto_accept") : t("disable_auto_accept")}
      </BlackButton>

      {disableMinutesOptions.map((minutes) => (
        <BlackButton key={minutes} onClick={() => onConfirm(minutes)}>
          {autoAcceptDisabled
            ? t("disable_auto_accept_another_minutes", { minutes })
            : t("disable_auto_accept_minutes", { minutes })}
        </BlackButton>
      ))}

      <BorderedButton onClick={onCancel}>
        {t("cancel")}
      </BorderedButton>
    </div>
  );

  return (
    <ModalNew
      customInnerComponent={popupInner}
      newModalContentClassName={styles.newModalContentClassName}
      newModalWrapClassName={`${styles.alertPopupWrap} ${showPopup ? styles.alertPopupWrapActive : ""}`}
    />
  );
};

export default forwardRef(AutoAcceptPopup);
